import * as React from "react"
import { Link, graphql } from 'gatsby'
import { linkResolver } from '../../../utils/link_resolver'
import Seo from '../../../components/seo'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { Fragment, useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const ProductSpecificationsPage = ({data}) => {
  const entry = data.prismicProductSpecificationsPage

  //console.log('items: ', items);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(entry.data.custom[0].sample);
  //console.log('start_image: ', image);
  let closeButtonRef = useRef(null)

  const handleSampleImage = e => {
    //console.log('handleImage: ', items[e.currentTarget.dataset.image].image );
    setImage(entry.data.custom[e.currentTarget.dataset.image].sample);
    setOpen(true);
  }

  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />

      <div className="w-full bg-black rounded-br-[133px]">
        <div className="flex-none md:flex justify-between">
          <div className="w-full md:w-6/12">
            <GatsbyImage className="w-full h-full block object-cover py-12 md:py-0" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
          </div>
          <div className="w-full md:w-6/12 px-8 sm:px-16 py-16 md:py-36">
            <div className="leading-snug text-2xl text-white font-poppins font-semibold">
              <h2 className="mb-8">
                <span className="bg-white text-3xl text-orange px-3 py-1 font-bold leading-tight inline-block">{entry.data.heading}</span>
              </h2>
              <p className="ml-3">{entry.data.text}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-white pt-16 pb-8">
        <div className="w-8/12 mx-auto max-w-screen-xl font-poppins text-center text-diamond text-2xl font-semibold leading-snug">
          <h2>{entry.data.custom_heading}</h2>
        </div>
      </div>
      {entry.data.custom[0].sample === null ? '' :
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={setOpen} initialFocus={closeButtonRef}>
          <div className="flex items-center justify-center max-h-screen py-8 px-8">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
            </Transition.Child>
            <span className="inline-block align-middle h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="inline-block bg-white rounded-lg overflow-hidden shadow-xl transform transition-all align-middle max-w-lg w-full p-8">
                <div className="block absolute top-0 right-0 pt-4 pr-4 z-50">
                  <button ref={closeButtonRef} type="button" className="bg-white p-2 rounded-md text-red hover:text-red focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-white" onClick={() => setOpen(false)}>
                    <span className="sr-only">Close</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="flex justify-center">
                  <GatsbyImage className="w-full h-full object-fit block" image={image.gatsbyImageData} alt={image.alt ?? ''} />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      }
      <div className="w-full pb-16 bg-white">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="grid grid-cols-1 md:grid-cols-5 gap-6">
          {entry.data.custom.map((entry, i) => {
            return (
              <div key={`c_${i}`}>
                <figure>
                  <GatsbyImage className="w-48 h-48 mx-auto lg:w-full lg:h-full object-cover block" image={entry.image1.gatsbyImageData} alt={entry.image1.alt ?? ''} />
                </figure>
                <figcaption className="font-poppins max-w-none prose prose-h2:text-xl prose-h2:text-orange prose-h2:mb-6 prose-h2:mt-6 prose-h2:font-semibold prose-p:font-medium leading-snug">
                  <h2>{entry.heading1}</h2>
                  <PrismicRichText linkResolver={linkResolver} field={entry.text1.richText} />
                </figcaption>
                {entry.sample.gatsbyImageData === null ? '' : <div tabIndex={i} role="button" type="button" data-image={i} onClick={handleSampleImage} className="pt-6 cursor-pointer font-poppins text-orange font-medium leading-snug">
                  View samples &#62;
                </div>}
              </div>
            )
          })}
          </div>
        </div>
      </div>

      <div className="w-full bg-white">
        <div className="w-full py-16 bg-diamond rounded-tl-[133px]">
          <div className="w-10/12 mx-auto max-w-screen-xl">
            <div className="sm:text-left flex-none sm:flex justify-between items-top gap-6 md:gap-0 lg:gap-12 xl:gap-6">
              <div className="flex justify-center w-full sm:w-4/12 lg:w-2/12 mb-12 sm:mb-0">
                <figure className="flex items-center justify-center w-36 h-36 lg:w-40 lg:h-40 bg-white p-3 rounded-full">
                  <GatsbyImage className="w-36 h-36 lg:w-40 lg:h-40 inline-block mx-auto bg-white p-3 rounded-full" image={entry.data.guarantee_image.gatsbyImageData} alt={entry.data.guarantee_image.alt ?? ''} /> 
                </figure>
              </div>  
              <div className="w-full sm:w-8/12 lg:w-10/12">
                <div className="text-center font-poppins font-semibold text-white text-2xl mb-6">
                  <h2>{entry.data.guarantee_heading}</h2>
                </div>
                <div className="font-poppins max-w-none prose prose-h2:text-white prose-h2:font-semibold prose-p:font-medium prose-p:text-white prose-li:text-white leading-snug">
                  <PrismicRichText linkResolver={linkResolver} field={entry.data.guarantee_text.richText} />
                </div>
              </div>   
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-16 bg-white">
        <div className="w-full py-16 bg-diamond-10 rounded-br-[133px]">
          <div className="w-10/12 mx-auto max-w-screen-xl">
            <div className="sm:text-left flex-none sm:flex justify-between items-top gap-6 md:gap-0 lg:gap-12 xl:gap-6">
              <div className="flex justify-center w-full sm:w-4/12 lg:w-2/12 mb-12 sm:mb-0">
                <figure className="flex items-center justify-center">
                  <GatsbyImage className="w-full h-full object-fit block mx-auto" image={entry.data.strapping_image.gatsbyImageData} alt={entry.data.strapping_image.alt ?? ''} /> 
                </figure>
              </div>  
              <div className="w-full sm:w-8/12 lg:w-10/12">
                <div className="text-center font-poppins font-semibold text-diamond text-2xl mb-6">
                  <h2>{entry.data.strapping_heading}</h2>
                </div>
                <div className="font-poppins max-w-none prose prose-h2:text-white prose-h2:font-semibold prose-p:font-medium prose-p:text-diamond-80 prose-li:text-diamond-80 leading-snug">
                  <PrismicRichText linkResolver={linkResolver} field={entry.data.strapping_text.richText} />
                </div>
              </div>   
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-8 bg-white">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="text-center font-poppins font-semibold text-diamond text-2xl mb-6">
            <h2>{entry.data.collar_heading}</h2>
          </div>
          {entry.data.collar.map((entry, i) => {
            return (
              <div key={`hc_${i}`} className="pb-8 w-full">
                <div className="sm:text-left flex-none sm:flex justify-between items-top gap-6 md:gap-0 lg:gap-12">
                  <div className="flex justify-center w-full lg:w-4/12 mb-12 sm:mb-0">
                    <figure className="p-6 flex items-center justify-center border-2 border-diamond-50 rounded-tl-[27px] rounded-tr-[27px] rounded-bl-[27px]">
                      <GatsbyImage className="w-full h-full object-fit block mx-auto" image={entry.image1.gatsbyImageData} alt={entry.image1.alt ?? ''} /> 
                    </figure>
                  </div>  
                  <div className="w-full lg:w-8/12">
                    <div className="font-poppins font-semibold text-orange text-xl mb-6">
                      <h2>{entry.heading1}</h2>
                    </div>
                    <div className="font-poppins max-w-none prose prose-h2:text-white prose-h2:font-semibold prose-p:font-medium prose-p:text-diamond-80 prose-li:text-diamond-80 leading-snug">
                      <PrismicRichText linkResolver={linkResolver} field={entry.text1.richText} />
                    </div>
                  </div>   
                </div>
              </div>
            )}
          )}
        </div>
      </div>

      <div className="w-full pb-8 bg-white">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="text-center font-poppins font-semibold text-diamond text-2xl mb-6">
            <h2>{entry.data.lead_heading}</h2>
          </div>
          {entry.data.lead.map((entry, i) => {
            return (
              <div key={`hl_${i}`} className="pb-8 w-full">
                <div className="sm:text-left flex-none sm:flex justify-between items-top gap-6 md:gap-0 lg:gap-12">
                  <div className="flex justify-center w-full lg:w-4/12 mb-12 sm:mb-0">
                    <figure className="p-6 flex items-center justify-center border-2 border-diamond-50 rounded-tl-[27px] rounded-tr-[27px] rounded-bl-[27px]">
                      <GatsbyImage className="w-full h-full object-fit block mx-auto" image={entry.image1.gatsbyImageData} alt={entry.image1.alt ?? ''} /> 
                    </figure>
                  </div>  
                  <div className="w-full lg:w-8/12">
                    <div className="font-poppins font-semibold text-orange text-xl mb-6">
                      <h2>{entry.heading1}</h2>
                    </div>
                    <div className="font-poppins max-w-none prose prose-h2:text-white prose-h2:font-semibold prose-p:font-medium prose-p:text-diamond-80 prose-li:text-diamond-80 leading-snug">
                      <PrismicRichText linkResolver={linkResolver} field={entry.text1.richText} />
                    </div>
                  </div>   
                </div>
              </div>
            )}
          )}
        </div>
      </div>

      <div className="w-full pb-16 bg-white">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="text-center font-poppins font-semibold text-diamond text-2xl mb-6">
            <h2>{entry.data.other_heading}</h2>
          </div>
          {entry.data.other.map((entry, i) => {
            return (
              <div key={`ho_${i}`} className="pb-8 w-full">
                <div className="sm:text-left flex-none sm:flex justify-between items-top gap-6 md:gap-0 lg:gap-12">
                  <div className="flex justify-center w-full lg:w-4/12 mb-12 sm:mb-0">
                    <figure className="p-6 flex items-center justify-center border-2 border-diamond-50 rounded-tl-[27px] rounded-tr-[27px] rounded-bl-[27px]">
                      <GatsbyImage className="w-full h-full object-fit block mx-auto" image={entry.image1.gatsbyImageData} alt={entry.image1.alt ?? ''} /> 
                    </figure>
                  </div>  
                  <div className="w-full lg:w-8/12">
                    <div className="font-poppins font-semibold text-orange text-xl mb-6">
                      <h2>{entry.heading1}</h2>
                    </div>
                    <div className="font-poppins max-w-none prose prose-h2:text-white prose-h2:font-semibold prose-p:font-medium prose-p:text-diamond-80 prose-li:text-diamond-80 leading-snug">
                      <PrismicRichText linkResolver={linkResolver} field={entry.text1.richText} />
                    </div>
                  </div>   
                </div>
              </div>
            )}
          )}
        </div>
      </div>

      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicProductSpecificationsPage {
    data {
      meta_description
      page_title
      heading
      image {
        alt
        gatsbyImageData
      }
      text
      custom_heading
      custom {
        image1 {
          alt
          gatsbyImageData
        }
        heading1
        text1 {
          richText
        }
        sample {
          alt
          gatsbyImageData
        }
      }
      guarantee_heading
      guarantee_image {
        alt
        gatsbyImageData
      }
      guarantee_text {
        richText
      }
      strapping_heading
      strapping_image {
        alt
        gatsbyImageData
      }
      strapping_text {
        richText
      }
      collar_heading
      collar {
        image1 {
          alt
          gatsbyImageData
        }
        heading1
        text1 {
          richText
        }
      }
      lead_heading
      lead {
        heading1
        image1 {
          alt
          gatsbyImageData
        }
        text1 {
          richText
        }
      }
      other_heading
      other {
        heading1
        image1 {
          alt
          gatsbyImageData
        }
        text1 {
          richText
        }
      }
      review_image {
        alt
        gatsbyImageData
      }
      review_quote
      review_author
    }
  }
}
`

export default ProductSpecificationsPage
